import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Theme } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    paddingBottom: 120
  },
  container: {
    position: 'relative',
    maxWidth: '676px',
    minHeight: '40vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  image: {
    width: '96px',
    height: '96px'
  },
  heading: {
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 20
  },
  subheading: {
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 16,
    marginTop: 16
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 999,
    width: '100%',
    maxWidth: '100%',
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontSize: 16,
    padding: 16
  },
  arrowIcon: {
    fontSize: 40,
    cursor: 'pointer'
  }
}))

const AppPage: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <Head>
        <title>Live Klassisk</title>
        <meta
          name='description'
          content='Live Klassisk – classical concerts, festivals, ensembles and venues in Scandinavia. Discover classical music with all the greatest artists in the world!'
        />
      </Head>
      <div className={classes.root}>
        <Container className={classes.container}>
          <div style={{ width: '100%', position: 'absolute' }}>
            <Link href='/'>
              <ChevronLeft className={classes.arrowIcon} />
            </Link>
          </div>

          <div className={classes.image}>
            <Image
              src='/images/logo_big.png'
              alt='Live Klassisk logo'
              width='96px'
              height='96px'
            />
          </div>
          <div className={classes.heading}>Live Klassisk</div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://apps.apple.com/dk/app/live-klassisk/id6444774319?l=da'
            >
              <div className={classes.button}>Download app (iOS)</div>
            </a>
          </div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://play.google.com/store/apps/details?id=com.ni.LiveKlassisk'
            >
              <div className={classes.button}>Download app (Android)</div>
            </a>
          </div>
        </Container>
      </div>
    </>
  )
}

export default AppPage
